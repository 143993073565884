const { Storage } = require("@capacitor/storage");

const dayjs = require("dayjs");
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);
require('dayjs/locale/zh-cn')
dayjs.locale('zh-cn')

dayjs.tz.setDefault('Asia/Shanghai')
function getDay() {
  return dayjs().format("YYMMDD");
}

function getTS() {
  return dayjs()
    .utc()
    .format("YYMMDDHHmmss");
}

async function addRecord(record) {
  const day = getDay();
  const records = await getRecord(day);
  record.push(getTS());
  if (records) {
    records.push(record);
    await Storage.set({
      key: day,
      value: JSON.stringify(records),
    });
  } else {
    await Storage.set({
      key: day,
      value: JSON.stringify([record]),
    });
  }
}

async function getRecord(day) {
  const {value} = await Storage.get({
    key: day,
  });

  if (value) {
    return JSON.parse(value)
  } else {
    return null;
  }
}

async function getAllRecordKeys() {
   let value = await Storage.keys();
   value = value.keys
   const wordlistIdx = value.indexOf('wordlist')
   if(wordlistIdx!=-1) {
    value.splice(wordlistIdx, 1)
   }
   return value
}

async function addWordList(book, cate, word) {
  const wordlist = await getWordList()

  if(wordlist) {
    if(!wordlist[word]) {
      wordlist[word] = [book,cate]
    }
    await Storage.set({
      key: 'wordlist',
      value: JSON.stringify(wordlist),
    });
  } else {
    const newWordlist = {}
    newWordlist[word] = [book,cate]
    await Storage.set({
      key: 'wordlist',
      value: JSON.stringify(newWordlist),
    });
  }
}

async function removeWordList(word) {
  const wordlist = await getWordList()
  if(wordlist) {
    if(wordlist[word]) {
      wordlist[word] = null
      delete wordlist[word]
    }
    await Storage.set({
      key: 'wordlist',
      value: JSON.stringify(wordlist),
    });
  } 
}

async function getWordList() {

  let {value} = await Storage.get({
    key: 'wordlist',
  });
  if(value) {
    value = JSON.parse(value)
  }
  return value||{}
}

async function getDailyCount() {
  // const dailyCount = []
  const countObj = []
  const days = await getAllRecordKeys()
  for(let i=days.length-1;i>=0;i--) {
    const records = await getRecord(days[i])
    const day = days[i]
    countObj.push(['20'+day.substr(0,2)+'-'+day.substr(2,2)+'-'+day.substr(4,2), records.length])
  }
  // let start = dayjs()
  // const end = dayjs().subtract(1, 'year').format("YYYY-MM-DD");
  // let i=0
  // while(i<366) {
  //   const daystr = start.format("YYYY-MM-DD")
  //   dailyCount.push([daystr, countObj[daystr]||0])
  //   start = start.subtract(1, 'day')
  //   if(start.format("YYYY-MM-DD")==end) {
  //     break
  //   }
  //   i++
  // }
  return countObj
}

function getWord(books, book, cate, word) {
  const words = books[book].words
  const cates = Object.keys(words) 
  const cateName = cates[cate]
  return Object.keys(words[cateName])[word]
}

async function getScoreCount(books) {
  const score = {}
  const scoreCount = {}
  const days = await getAllRecordKeys()
  for(let i=days.length-1;i>=0;i--) {
    const records = await getRecord(days[i])
    records.forEach(record => {
      const word = getWord(books, record[0], record[1], record[2])

      if(record[4]) {
        score[word] = score[word] || 0
        score[word] = score[word]+1
      }
    });
  }
  console.log(score);
  const scoreKeys = Object.keys(score)

  scoreCount['all'] = scoreKeys.length
  scoreKeys.forEach(key=>{
    if(score[key]>5) {
      scoreCount['5+'] = scoreCount['5+'] || 0
      scoreCount['5+'] = scoreCount['5+']+1
    } else {
      scoreCount[score[key]] = scoreCount[score[key]] || 0
      scoreCount[score[key]] = scoreCount[score[key]]+1
    }
  })
  return scoreCount
}

module.exports = {
  addRecord,
  getRecord,
  getAllRecordKeys,
  getTS,
  getDay,
  addWordList,
  removeWordList,
  getWordList,
  getDailyCount,
  getScoreCount
};
