<template>
  <v-breadcrumbs
    :items="items"
    divider="/"
    style="padding-top:10px;padding-bottom:10px"
  ></v-breadcrumbs>
</template>

<script>
import { mapState } from "vuex";
import { mixin } from "./mixin";
export default {
  name: "Breadcrumbs",
  mixins: [mixin],
  data() {
    return {};
  },
  mounted() {},
  computed: {
    ...mapState(["books", "bookIdx", "cateIdx"]),
    bookName() {
      return this.books[this.bookIdx].name;
    },
    cateName() {
      return Object.keys(this.books[this.bookIdx].words)[this.cateIdx];
    },
    items() {
      return [
        {
          text: this.bookName,
          disabled: false,
          href: '#/books/'+this.bookIdx+'/'+this.cateIdx,
        },
        {
          text: this.cateName,
          disabled: true,
          href: "#",
        },
      ];
    },
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
