import Vue from "vue";
import Router from "vue-router";
import Books from "./components/Books.vue";
import Words from "./components/Words.vue";
import Tests1 from "./components/Tests1.vue";
import Tests2 from "./components/Tests2.vue";
import Tests3 from "./components/Tests3.vue";
import Tests4 from "./components/Tests4.vue";
import TestsHome from "./components/TestsHome.vue";
import Log from "./components/Log.vue";
import FavWords from "./components/FavWords.vue";

Vue.use(Router);

export default new Router({
  //   mode: 'history',
  //   base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: Books,
    },
    {
      path: "/books/:bookIndex/:cateIndex",
      name: "books",
      component: Books,
      props: true,
    },
    {
      path: "/words/:bookIndex/:cateIndex",
      name: "words",
      component: Words,
      props: true,
    },
    {
      path: "/tests/:bookIndex/:cateIndex",
      name: "tests",
      component: TestsHome,
      props: true,
    },
    {
      path: "/tests1/:bookIndex/:cateIndex",
      name: "tests1",
      component: Tests1,
      props: true,
    },
    {
      path: "/tests2/:bookIndex/:cateIndex",
      name: "tests2",
      component: Tests2,
      props: true,
    },
    {
      path: "/tests3/:bookIndex/:cateIndex",
      name: "tests3",
      component: Tests3,
      props: true,
    },
    {
      path: "/tests4/:bookIndex/:cateIndex",
      name: "tests4",
      component: Tests4,
      props: true,
    },
    {
      path: "/exam/:bookIndex/:cateIndex",
      name: "exam",
      component: TestsHome,
      props: true,
    },
    {
      path: "/exam1/:bookIndex/:cateIndex",
      name: "exam1",
      component: Tests1,
      props: true,
    },
    {
      path: "/exam2/:bookIndex/:cateIndex",
      name: "exam2",
      component: Tests2,
      props: true,
    },
    {
      path: "/exam3/:bookIndex/:cateIndex",
      name: "exam3",
      component: Tests3,
      props: true,
    },
    {
      path: "/exam4/:bookIndex/:cateIndex",
      name: "exam4",
      component: Tests4,
      props: true,
    },
    {
      path: "/log",
      name: "log",
      component: Log,
      props: true,
    },
    {
      path: "/fav",
      name: "fav",
      component: FavWords,
      props: true,
    },
  ],
});
