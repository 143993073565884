<template>
  <!-- <van-sticky :offset-top="46">
      <van-tabs v-model="wordsTab">
        <van-tab title="显示全部" name="1"> </van-tab>
        <van-tab title="只显示英文" name="2"> </van-tab>
        <van-tab title="只显示中文" name="3"> </van-tab>
      </van-tabs>
    </van-sticky> -->

  <v-list subheader two-line>
    <!-- <v-subheader inset>{{ selectedCate }}</v-subheader> -->

    <v-list-item v-for="word in words" :key="word" @click="function() {speak(word)}">
      <v-list-item-avatar>
        <v-btn @click="speak(word)" fab>
          <v-icon class=" lighten-2">
            mdi-volume-source
          </v-icon>
        </v-btn>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title
          v-text="wordsTab == 3 ? '-' : word"
        ></v-list-item-title>

        <v-list-item-subtitle
          v-text="wordsTab == 2 ? '-' : selectedBook[selectedCate][word]"
        ></v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-action>
        <v-btn icon @click="addWord(word)">
          <!-- <v-icon color="grey lighten-1">mdi-star-outline</v-icon> -->

          <v-icon
            v-if="selectedWords.indexOf(word) == -1"
            color="grey lighten-1"
          >
            mdi-star-outline
          </v-icon>

          <v-icon v-else color="yellow darken-3">
            mdi-star
          </v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
  </v-list>
  <!--     
    <van-cell-group :border="false">
      <van-cell
        center
        size="large"
        v-for="word in words"
        :key="word"
        :label="wordsTab == 2 ? '-' : selectedBook[selectedCate][word]"
      >
        <template #title>
          <div>
            <van-button
              size="mini"
              round
              icon="service-o"
              type="default"
              @click="speak($event, word)"
            />
            <span style="margin-left:10px">{{ wordsTab == 3 ? "-" : word }}</span>
          </div>
        </template>

        <template #extra>
          <van-button
            size="mini"
            round
            icon="star-o"
            type="default"
            @click="onClickAddNotes($event, word)"
          />
        </template>
      </van-cell>
    </van-cell-group> -->
</template>

<script>
import { mixin } from "./mixin";
// import storage from "../utils/storage";
export default {
  name: "Words",
  mixins: [mixin],
  data() {
    return {
      wordsTab: "1",

    };
  },
  mounted() {
    this.loadFavWord();
  },
  computed: {

  },
  methods: {

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
