import { EventBus } from "../main"
import { mapState, mapMutations } from "vuex";
import { speak } from "../utils/tools";
import keyboard from "./keyboard";
import resultDialog from "./ResultDialog";
import storage from "../utils/storage";


export const mixin = {
  components: { keyboard, resultDialog },
  props: ["bookIndex", "cateIndex"],
  computed: {
    ...mapState(["books", "activeKey", "bookIdx", "cateIdx"]),
    selectedBook() {
      return this.books[this.bookIdx].words;
    },
    selectedCate() {
      return Object.keys(this.selectedBook)[this.cateIdx];
    },
    // isTest() {
    //   const routeName = this.$route.name;
    //   return (
    //     routeName === "tests" ||
    //     routeName === "tests1" ||
    //     routeName === "tests2" ||
    //     routeName === "tests3" ||
    //     routeName === "tests4"
    //   );
    // },
    // isExam() {
    //   const routeName = this.$route.name;
    //   return (
    //     routeName === "exam" ||
    //     routeName === "exam1" ||
    //     routeName === "exam2" ||
    //     routeName === "exam3" ||
    //     routeName === "exam4"
    //   );
    // },
    words() {
      return Object.keys(this.selectedBook[this.selectedCate]);
    },

    wordsCount() {
      let count = 0;
      Object.keys(this.selectedBook).forEach((cate) => {
        count += Object.keys(this.selectedBook[cate]).length;
      });
      return count;
    },

  },
  data() {
    return {
      resultDialog: false,
      correct: false,
      testType: 0,
      testResults: [],
      testResults1: [],
      testResults2: [],
      questionIdx: 0,
      isExam: true,
      selectedWords: [],
    };
  },
  mounted() {
    // eslint-disable-next-line no-debugger
    //   debugger
    this.setBookIdx(this.bookIndex);
    this.setCateIdx(this.cateIndex);
    const routeName = this.$route.name;
    if (routeName === "books") {
      this.setActiveKey(0);
    } else if (routeName === "words") {
      this.setActiveKey(0);
    } else if (routeName.indexOf("exam")!=-1) {
      this.setActiveKey(1);
    } else if (routeName === "log") {
      this.setActiveKey(3);
    } else if (routeName === "fav") {
      this.setActiveKey(2);
    }

  },
  methods: {
    speak(word) {
      speak(word);
    },
    wordIndex(word) {
      return this.words.indexOf(word);
    },
    logResult() {
      if (this.isExam) {
        const wordIdx = this.wordIndex(this.questions[this.questionIdx].word);
        this.addRecord([
          this.bookIdx,
          this.cateIdx,
          wordIdx,
          this.testType,
          this.correct,
        ]);
      }
    },
    openResultDialog() {
      EventBus.$emit("open-result-dialog")
    },

    onCloseDialog(needSpeak) {
      const that = this
      if (this.isExam) {
        if (this.questionIdx < that.questions.length - 1) {
          this.questionIdx++;
          if(needSpeak) {
            setTimeout(() => {
              that.speak(that.questions[that.questionIdx].word);
            }, 500);
          }
        } else
          this.$router.push({
            name: "exams",
            params: { bookIndex: this.bookIndex, cateIndex: this.cateIndex },
          });
      } else {
        if (this.testResults) {
          this.testResults[this.questionIdx] = "";
        }
        if (this.testResults1) {
          this.testResults1[this.questionIdx] = "";
        }
      }
    },
    addWord(word) {
      const idx = this.selectedWords.indexOf(word);
      (async () => {
        if (idx == -1) {
          await storage.addWordList(this.bookIdx, this.cateIdx, word);
        } else {
          await storage.removeWordList(word);
        }
        this.loadFavWord();
      })();
    },
    loadFavWord() {
      const that = this;
      (async () => {
        const list = await storage.getWordList();
        that.selectedWords = Object.keys(list);
        that.$forceUpdate();
      })();
    },
    ...mapMutations(["setActiveKey", "setBookIdx", "setCateIdx", "addRecord"]),
  },
};
