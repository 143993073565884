<template>


  <v-list subheader three-line>


    <v-list-item v-for="word in selectedWords" :key="word">
      <v-list-item-avatar>
        <v-btn @click="speak(word)" fab>
          <v-icon class=" lighten-2">
            mdi-volume-source
          </v-icon>
        </v-btn>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title
          v-text="wordsTab == 3 ? '-' : word"
        ></v-list-item-title>

        <v-list-item-subtitle
          v-text="wordsTab == 2 ? '-' : wordCH(word)"
        ></v-list-item-subtitle>

        <v-list-item-subtitle style="font-size:11px"
          v-text="wordBook(word)+' - '+wordCate(word)"
        ></v-list-item-subtitle>

      </v-list-item-content>

      <v-list-item-action>
        <v-btn icon @click="addWord(word)">

          <v-icon
            v-if="selectedWords.indexOf(word) == -1"
            color="grey lighten-1"
          >
            mdi-star-outline
          </v-icon>

          <v-icon v-else color="yellow darken-3">
            mdi-star
          </v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>

<script>
import { mixin } from "./mixin";
import storage from "../utils/storage";
export default {
  name: "FavWords",
  mixins: [mixin],
  data() {
    return {
      wordsTab: "1",
      selectedWords: [],
      wordlist:{}
    };
  },
  mounted() {
    this.loadFavWord()
  },
  computed: {
    wordsCount() {
      let count = 0;
      Object.keys(this.selectedBook).forEach((cate) => {
        count += Object.keys(this.selectedBook[cate]).length;
      });
      return count;
    },
    words() {
      return Object.keys(this.selectedBook[this.selectedCate]);
    },
  },
  methods: {
    addWord(word) {
      const idx = this.selectedWords.indexOf(word);
      (async () => {
        if (idx == -1) {
          await storage.addWordList(this.bookIdx, this.cateIdx, word);
        } else {
          await storage.removeWordList(word);
        }
        this.loadFavWord()
      })();
    },
    loadFavWord() {
      const that = this;
      (async () => {
        const list = await storage.getWordList();
        that.wordlist = list
        that.selectedWords = Object.keys(list);
        that.$forceUpdate()
      })();
    },
    wordCH(word) {
      const book = this.books[this.wordlist[word][0]]
      const cateName = Object.keys(book.words)[this.wordlist[word][1]]
      const cateWords = book.words[cateName]
      const ch = cateWords[word]
      return ch
    },
    wordBook(word) {
      const book = this.books[this.wordlist[word][0]]
      return book.name
    },
    wordCate(word) {
      const book = this.books[this.wordlist[word][0]]
      const cateName = Object.keys(book.words)[this.wordlist[word][1]]
      return cateName
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
