<template>
  <v-card elevation="2" shaped tile>
    <v-card-title style="font-size:20px">
      {{ questions[questionIdx].ch }}
    </v-card-title>
    <v-list subheader two-line flat>
      <v-list-item-group v-model="testResults[questionIdx]">
        <v-radio-group v-model="testResults[questionIdx]">
          <v-list-item
            v-for="(option, optionIdx) in questions[questionIdx].options"
            :key="optionIdx"
          >
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-radio :input-value="active" color="primary"> </v-radio>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>{{ option }}</v-list-item-title>
                <!-- <v-list-item-subtitle>Allow notifications</v-list-item-subtitle> -->
              </v-list-item-content>
            </template>
          </v-list-item>
        </v-radio-group>
      </v-list-item-group>
    </v-list>
    <v-btn block @click="onClickNext">
      下一题 ({{ questionIdx + 1 + "/" + this.questions.length }})
    </v-btn>

    <result-dialog
      :expected1="expected"
      :model="resultDialog"
      :exam="isExam"
    ></result-dialog>

    <!-- <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title>
          回答错误， 以下是正确答案
        </v-card-title>



        <v-card-text style="font-size:20px">
          {{expected}}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">
            关闭
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
  </v-card>
</template>

<script>
import { mixin } from "./mixin";
import { ch2en } from "../utils/tools";
import { EventBus } from "../main";
export default {
  mixins: [mixin],
  data: () => ({}),
  mounted() {
    const that = this;
    EventBus.$on("close-result-dialog", () => {
      that.onCloseDialog(false);
    });
  },
  beforeDestroy() {
    EventBus.$off("close-result-dialog");
  },
  computed: {
    questions() {
      return ch2en(this.selectedBook, this.selectedCate);
    },
    expected() {
      const expected = this.questions[this.questionIdx].word;
      return expected;
    },
  },
  methods: {
    onClickNext() {
      const expected = this.questions[this.questionIdx].word;
      const actual = this.questions[this.questionIdx].options[
        this.testResults[this.questionIdx]
      ];
      if (expected == actual) {
        this.correct = true;
        if (this.questionIdx < this.questions.length - 1) this.questionIdx++;
      } else {
        this.openResultDialog();
        this.correct = false;
      }
      this.logResult(4);
    },
  },
};
</script>
