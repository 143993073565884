<template>
  <v-card elevation="2" shaped tile>
    <!-- <v-switch v-model="isExam" :label="isExam ? '测试' : '练习'"></v-switch> -->

    <v-list flat>
      <v-list-item-group v-model="selectedItem" color="primary">
        <v-list-item @click="startTest(1)">
          <v-list-item-icon>
            <v-icon v-text="'mdi-flag'"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              v-text="'听英文默写并选中文'"
            ></v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="startTest(2)">
          <v-list-item-icon>
            <v-icon v-text="'mdi-flag'"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="'按中文默写英文'"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="startTest(3)">
          <v-list-item-icon>
            <v-icon v-text="'mdi-flag'"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="'按英文选中文'"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="startTest(4)">
          <v-list-item-icon>
            <v-icon v-text="'mdi-flag'"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="'按中文选英文'"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
import { mixin } from "./mixin";
export default {
  mixins: [mixin],
  data: () => ({
    selectedItem: -1,
  }),
  mounted() {
    // if(this.$route.name==='tests')
    //   this.setActiveKey(2)
    // else if(this.$route.name==='exam')
    //   this.setActiveKey(3)
  },
  computed: {},
  methods: {
    startTest(type) {
      if (this.isExam) {
        this.$router.push({
          name: "exam" + type,
          params: { bookIndex: this.bookIdx, cateIndex: this.cateIdx },
        });
      } else {
        this.$router.push({
          name: "tests" + type,
          params: { bookIndex: this.bookIdx, cateIndex: this.cateIdx },
        });
      }
    },
  },
};
</script>
