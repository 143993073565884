<template>
  <!-- <div class="app">
    <van-collapse v-model="activeBook">
      <van-collapse-item
        :title="book.name"
        :name="bookIdx"
        :key="bookIdx"
        icon="points"
        v-for="(book, bookIdx) in books"
      >
        <van-cell-group :border="false">
          <van-cell
            icon="location-o"
            is-link
            v-for="(cate, cateIdx) in Object.keys(book.words)"
            :key="cateIdx"
            :title="cate"
            :label="'共 ' + Object.keys(book.words[cate]).length + ' 词'"
            @click="onCateSelected($event, bookIdx, cateIdx)"
          />
        </van-cell-group>
      </van-collapse-item>
    </van-collapse>
  </div> -->
  <v-expansion-panels v-model="activeBook" focusable>
    <v-expansion-panel :key="bookIdx" v-for="(book, bookIdx) in books">
      <v-expansion-panel-header>{{ book.name }}</v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-list>
          <v-list-item-group>
            <v-list-item
              v-for="(cate, cateIdx) in Object.keys(book.words)"
              :key="cateIdx"
              @click="onCateSelected($event, bookIdx, cateIdx)"
            >
              <v-list-item-avatar>
                <v-icon class="grey lighten-1" dark>
                  mdi-folder
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-text="cate"></v-list-item-title>

                <v-list-item-subtitle
                  v-text="'共 ' + Object.keys(book.words[cate]).length + ' 词'"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
// import { mapState, mapMutations } from "vuex";
import { mixin } from "./mixin";
// import utters from "utters";

// Vue.use(VueTouchKeyboard);
export default {
  name: "Books",

  mixins: [mixin],
  data() {
    return {
      activeBook: 0,
    };
  },
  mounted() {
    this.setBookIdx(this.bookIdx || 0);
    this.setCateIdx(this.cateIdx || 0);
  },
  computed: {
    // ...mapState(["books", "activeKey", "bookIdx", "cateIdx"]),
    category() {
      return Object.keys(this.selectedBook);
    },
    wordsCount() {
      let count = 0;
      Object.keys(this.selectedBook).forEach((cate) => {
        count += Object.keys(this.selectedBook[cate]).length;
      });
      return count;
    },
  },
  methods: {
    onCateSelected(event, bookIdx, cateIdx) {
      // this.selectedBook = this.books[bookIdx].words;
      //   this.cateIdx = cateIdx;
      //   this.bookIdx = bookIdx;
      this.$router.push({
        name: "words",
        params: { bookIndex: bookIdx, cateIndex: cateIdx },
      });
      // this.setActiveKey(1);
      this.activeBook = bookIdx;
      this.setBookIdx(bookIdx);
      this.setCateIdx(cateIdx);
    },
    // ...mapMutations(["setActiveKey", "setBookIdx", "setCateIdx"]),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
