const dayjs = require("dayjs");
var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);
var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);
var timezone = require("dayjs/plugin/timezone");
dayjs.extend(timezone);
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);
const { NativeAudio } = require("@capacitor-community/native-audio");
const { TextToSpeech } = require("@capacitor-community/text-to-speech");
const { Capacitor } = require("@capacitor/core");

// const { Storage } = require("@capacitor/storage");

function randomSortWords(words, len) {
  let examWords = words.map((x) => x);
  if (len) examWords = examWords.slice(0, Math.min(len, examWords.length));
  examWords = examWords.sort(() => Math.random() - 0.5);
  return examWords;
}

function en2ch(book, cate) {
  let examWords = Object.keys(book[cate]);
  examWords = randomSortWords(examWords);
  let examWordsCH = Object.values(book[cate]);
  const test = [];
  examWords.forEach((word) => {
    let examWordsCH2 = randomSortWords(examWordsCH);
    let examWordsCHOptions = examWordsCH2.slice(0, 3);
    while (examWordsCHOptions.indexOf(book[cate][word]) != -1) {
      examWordsCH2 = randomSortWords(examWordsCH);
      examWordsCHOptions = examWordsCH2.slice(0, 3);
    }
    examWordsCHOptions.push(book[cate][word]);
    examWordsCHOptions = randomSortWords(examWordsCHOptions);
    // console.log(word + "\n"+examWordsCHOptions.join('\n'));
    test.push({
      word: word,
      ch: book[cate][word],
      options: examWordsCHOptions,
    });
  });
  return test;
}

function ch2en(book, cate) {
  let examWords = Object.keys(book[cate]);
  examWords = randomSortWords(examWords);
  const test = [];
  examWords.forEach((word) => {
    let examWords2 = randomSortWords(examWords);
    let examWordsOptions = examWords2.slice(0, 3);
    while (examWordsOptions.indexOf(word) != -1) {
      examWords2 = randomSortWords(examWords);
      examWordsOptions = examWords2.slice(0, 3);
    }
    examWordsOptions.push(word);
    examWordsOptions = randomSortWords(examWordsOptions);
    // console.log(word + "\n"+examWordsCHOptions.join('\n'));
    test.push({
      word: word,
      ch: book[cate][word],
      options: examWordsOptions,
    });
  });
  return test;
}

function speak2(word) {
  // const firstChar = word.charAt(0);
  // const audioFile = require(`../vocabulary/base64/${word}.json`)
  // const audio = new Audio("data:audio/wav;base64,"+audioFile[0]);
  // audio.play();
  if (Capacitor.isNativePlatform()) {
    NativeAudio.preload({
      assetId: `${word}`,
      assetPath: `wav/${word}-Zira.wav`,
      audioChannelNum: 1,
      // isUrl: false,
    }).then(() => {
      NativeAudio.play({
        assetId: `${word}`,
        // time: 6.0 - seek time
      });
    });
  } else {
    (async () => {
      await TextToSpeech.speak({
        text: `${word}`,
        lang: "en_US",
        rate: 0.7,
        pitch: 1.0,
        volume: 1.2,
        category: "ambient",
      });
    })();
  }
}

async function speak(word) {
  speak2(word);
  return;
  // if(window.speechSynthesis) {
  //   let utter = new SpeechSynthesisUtterance();
  //   utter.lang = "en-US";
  //   utter.text = word;
  //   utter.volume = 1.5;
  //   utter.rate = 1
  //   utter.pitch = 1
  //   window.speechSynthesis.speak(utter);
  // } else {

  // await TextToSpeech.speak({
  //   text: word,
  //   lang: 'en_US',
  //   rate: 1.0,
  //   pitch: 1.0,
  //   volume: 1.5,
  //   category: 'ambient',
  // });
  // }
}

function dayFormat(str) {
  return dayjs(str, "YYMMDD", true).format("dddd, MMMM DD, YYYY");
}

function tsFormat(str) {
  const ts = dayjs(str, "YYMMDDHHmmss", true).format("YYYY-MM-DD HH:mm:ss UTC");
  return dayjs(ts).format("LLLL");
  //return dayjs.tz(str, "Asia/Shanghai").format('LLLL')
  // return dayjs(str, 'YYMMDDHHmmss', true).format('LLLL')
}

// export default
module.exports = {
  randomSortWords,
  en2ch,
  ch2en,
  speak,
  dayFormat,
  tsFormat,
};
