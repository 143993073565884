// import { createApp, Vue } from 'vue'
import Vue from 'vue'
import App from './App.vue'
// import Vant from 'vant';
import router from './router'
import store from './store'

// import VueRouter from "vue-router";
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import vuetify from './plugins/vuetify'

// import VueCalendarHeatmap from 'vue-calendar-heatmap'

// Vue.use(VueCalendarHeatmap)

Vue.use(Vuetify)


// Vue.component('v-chart', ECharts)

// import 'vant/lib/index.css';
// Vue.config.productionTip = false
// Vue.use(Vant);
// Vue.use(VueRouter)
// 1. Define route components.
// These can be imported from other files
// const Foo = { template: '<div>foo</div>' }
// const Bar = { template: '<div>bar</div>' }
// import Books from "./components/Books.vue";

// 2. Define some routes
// Each route should map to a component. The "component" can
// either be an actual component constructor created via
// `Vue.extend()`, or just a component options object.
// We'll talk about nested routes later.
// const routes = [
//   { path: '/foo', component: Foo },
//   { path: '/bar', component: Bar },
//   { path: '/books', component: Books }
// ]

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
// const router = new VueRouter({
//   routes // short for `routes: routes`
// })

export const EventBus = new Vue({
});

new Vue({
  render: h => h(App),
  router,
  vuetify,
  store
}).$mount('#app')

