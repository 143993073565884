<template>
  <v-dialog v-model="model" width="500">
    <v-card>
      <v-card-title style="font-size:15px">
        回答错误， 以下是正确答案
      </v-card-title>

      <v-card-text style="font-size:20px">
        {{ expected1 }}
      </v-card-text>

      <v-card-text style="font-size:20px" v-if="expected2">
        {{ expected2 }}
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="onCloseDialog">
          关闭
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { EventBus } from "../main";
export default {
  props: ["expected1", "expected2"],

  data: () => ({
    model: false,
  }),
  mounted() {
    this.model = false;
    EventBus.$on("open-result-dialog", ()=>{
        this.model = true;
    })
  },
  computed: {},
  methods: {
    onCloseDialog() {
      this.model = false;
      EventBus.$emit("close-result-dialog");
    },
  },
};
</script>
