<template>
  <v-card
    elevation="2"
    shaped
    tile
    v-if="questions[questionIdx] && questions[questionIdx].options"
  >
    <v-card-title style="font-size:20px; text-align:center">
      <v-btn x-small @click="speak(questions[questionIdx].word)" fab>
        <v-icon class=" lighten-2">
          mdi-volume-source
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-title style="font-size:25px; text-align:center">
      <span style="margin: 0 auto;min-height:30px">{{
        testResults1[questionIdx]
      }}</span>
    </v-card-title>

    <keyboard
      v-model="testResults1[questionIdx]"
      :layouts="[
        'qwertyuiop|asdfghjkl|{shift:goto:1}zxcvbnm|{delete:backspace}{space:space}',
        'QWERTYUIOP|ASDFGHJKL|{shift:goto:0}ZXCVBNM|{delete:backspace}{space:space}',
      ]"
      :maxlength="16"
    ></keyboard>

    <v-divider style="margin: 10px" />

    <v-list subheader dense>
      <v-list-item-group v-model="testResults2[questionIdx]">
        <v-radio-group v-model="testResults2[questionIdx]">
          <v-list-item
            v-for="(option, optionIdx) in questions[questionIdx].options"
            :key="optionIdx"
          >
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-radio :input-value="active" color="primary"> </v-radio>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>{{ option }}</v-list-item-title>
                <!-- <v-list-item-subtitle>Allow notifications</v-list-item-subtitle> -->
              </v-list-item-content>
            </template>
          </v-list-item>
        </v-radio-group>
      </v-list-item-group>
    </v-list>

    <v-btn block @click="onClickNext">
      下一题 ({{ questionIdx + 1 + "/" + this.questions.length }})
    </v-btn>
    <result-dialog
      :expected1="expected1"
      :expected2="expected2"
      :model="resultDialog"
      :exam="isExam"
    ></result-dialog>

    <!-- <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title style="font-size:15px">
          回答错误， 以下是正确答案
        </v-card-title>

        <v-card-text style="font-size:20px">
          {{ expected1 }}
        </v-card-text>

        <v-card-text style="font-size:20px">
          {{ expected2 }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">
            关闭
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
  </v-card>
</template>

<script>
import { mixin } from "./mixin";
import { en2ch } from "../utils/tools";
import ResultDialog from "./ResultDialog.vue";
import { EventBus } from "../main";

export default {
  components: { ResultDialog },
  mixins: [mixin],

  data: () => ({}),

  mounted() {
    const that = this;
    EventBus.$on("close-result-dialog", () => {
      that.onCloseDialog(true);
    });

    setTimeout(() => {
      that.speak(that.questions[that.questionIdx].word);
    }, 2000);
  },
  beforeDestroy() {
    EventBus.$off("close-result-dialog");
  },
  computed: {
    questions() {
      const q = en2ch(this.selectedBook, this.selectedCate);
      return q;
    },
    expected1() {
      const expected = this.questions[this.questionIdx].word;
      return expected;
    },
    expected2() {
      const expected = this.questions[this.questionIdx].ch;
      return expected;
    },
  },
  methods: {
    onClickNext() {
      // const expected1 = this.questions[this.questionIdx].word;
      const actual1 = this.testResults1[this.questionIdx];
      // const expected2 = this.questions[this.questionIdx].ch;
      const actual2 = this.questions[this.questionIdx].options[
        this.testResults2[this.questionIdx]
      ];

      if (this.expected1 == actual1 && this.expected2 == actual2) {
        if (this.questionIdx < this.questions.length - 1) this.questionIdx++;
        const that = this;
        setTimeout(() => {
          that.speak(that.questions[that.questionIdx].word);
        }, 500);
        this.correct = true;
      } else {
        this.openResultDialog();
        this.correct = false;
      }
      this.logResult(1);
    },
    // onCloseDialog() {
    //   this.resultDialog = false;
    //   if (this.isExam) {
    //     if (this.questionIdx < this.questions.length - 1) this.questionIdx++;
    //     else
    //       this.$router.push({
    //         name: "exams",
    //         params: { bookIndex: this.bookIndex, cateIndex: this.cateIndex },
    //       });
    //   } else {
    //     this.testResults1[this.questionIdx] = "";
    //   }
    // },
  },
};
</script>
