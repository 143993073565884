<template>
  <div>
    <div
      style="width:100%;height:260px;padding-bottom:20px"
      id="waterfall-chart-wrapper"
    >
      <div style="width:100%;height:250px" id="waterfall-chart"></div>
    </div>
    <div
      style="width:100%;height:260px;padding-bottom:20px"
      id="heatmap-chart-wrapper"
    >
      <div style="width:100%;height:250px" id="heatmap-chart"></div>
    </div>
    <v-expansion-panels v-model="activeDay" focusable>
      <v-expansion-panel :key="logIdx" v-for="(log, logIdx) in logs">
        <v-expansion-panel-header>{{
          dayFormat(log.day)
        }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-list three-line>
            <v-list-item-group>
              <v-list-item
                v-for="(record, recordIdx) in log.records"
                :key="recordIdx"
              >
                <!-- <v-list-item-avatar>
                  <v-icon v-if="record[4]" small>
                    mdi-check
                  </v-icon>

                  <v-icon v-else small>
                    mdi-window-close
                  </v-icon>
                </v-list-item-avatar> -->

                <v-list-item-content>
                  <v-list-item-title
                    v-text="getWordInfo(record)[2]"
                  ></v-list-item-title>
                  <v-list-item-subtitle
                    v-text="getWordInfo(record)[3]"
                  ></v-list-item-subtitle>
                  <v-list-item-subtitle
                    style="font-size:12px"
                    v-text="
                      getWordInfo(record)[0] + '-' + getWordInfo(record)[1]
                    "
                  ></v-list-item-subtitle>
                  <!-- <v-list-item-subtitle style="font-size:10px"
                  v-text="tsFormat(record[5])"
                ></v-list-item-subtitle> -->
                </v-list-item-content>

                <v-list-item-action>
                  <!-- <v-list-item-action-text
                    v-text="tsFormat(record[5])"
                  ></v-list-item-action-text> -->
                  <v-list-item-action-text
                    v-text="record[4] ? '正确' : '错误'"
                  ></v-list-item-action-text>
                  <v-btn icon @click="addWord(getWordInfo(record)[2])">
                    <!-- <v-icon color="grey lighten-1">mdi-star-outline</v-icon> -->

                    <v-icon
                      v-if="selectedWords.indexOf(getWordInfo(record)[2]) == -1"
                      color="grey lighten-1"
                    >
                      mdi-star-outline
                    </v-icon>

                    <v-icon v-else color="yellow darken-3">
                      mdi-star
                    </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
// import { mapState, mapMutations } from "vuex";
import { mixin } from "./mixin";
// import utters from "utters";
import storage from "../utils/storage";
import { dayFormat, tsFormat } from "../utils/tools";
import * as echarts from "echarts";

// Vue.use(VueTouchKeyboard);

// import ECharts from "vue-echarts";
// import "echarts/lib/chart/line";
// import "echarts/lib/component/polar";

export default {
  name: "Log",
  mixins: [mixin],
  components: {},
  data() {
    return {
      logs: [],
      activeDay: -1,
      dailyHeatData: [],
    };
  },
  mounted() {
    const that = this;
    this.loadFavWord();
    (async () => {
      that.days = await storage.getAllRecordKeys();
      for (var i = 0; i < that.days.length; i++) {
        const day = that.days[i];
        const records = await storage.getRecord(day);
        that.logs.push({ day: day, records: records });
      }

      const scores = await storage.getScoreCount(this.books);
      console.log(scores);
      this.initWaterfall(scores);

      const daily = await storage.getDailyCount();
      this.dailyHeatData = daily;

      this.initHeatMap();
    })();
    // this.dailyHeatData = this.getVirtulData(2021);
  },
  computed: {},
  methods: {
    dayFormat(day) {
      const date = dayFormat(day);
      return date;
    },
    tsFormat(day) {
      const ts = tsFormat(day);
      return ts;
    },
    getWordInfo(record) {
      const book = this.books[record[0]].name;
      const words = this.books[record[0]].words;
      const cates = Object.keys(words);
      const cate = cates[record[1]];
      const cateWords = words[cate];
      const cateWordKeys = Object.keys(cateWords);
      const word = cateWordKeys[record[2]];
      const ch = words[cate][word];
      return [book, cate, word, ch];
    },
    getVirtulData(year) {
      year = year || "2017";
      var date = +echarts.number.parseDate(year + "-01-01");
      var end = +echarts.number.parseDate(+year + 1 + "-01-01");
      var dayTime = 3600 * 24 * 1000;
      var data = [];
      for (var time = date; time < end; time += dayTime) {
        data.push([
          echarts.format.formatTime("yyyy-MM-dd", time),
          Math.floor(Math.random() * 10000),
        ]);
      }
      return data;
    },

    initHeatMap() {
      var heatmapChartDom = document.getElementById("heatmap-chart");
      var heatmapChart = echarts.init(heatmapChartDom);

      var heatmapoption;

      heatmapoption = {
        title: {
          top: 30,
          left: "center",
          text: "测试热力图",
        },
        tooltip: {},
        visualMap: {
          min: 0,
          max: 300,
          // type: "piecewise",
          orient: "horizontal",
          left: "center",
          top: 65,
        },
        calendar: {
          top: 120,
          left: 30,
          right: 30,
          cellSize: ["auto", 10],
          range: "2021",
          itemStyle: {
            borderWidth: 0.5,
          },
          yearLabel: { show: true },
        },
        series: {
          type: "heatmap",
          coordinateSystem: "calendar",
          data: this.dailyHeatData,
        },
      };
      console.log(this.dailyHeatData);
      heatmapoption && heatmapChart.setOption(heatmapoption);
    },
    initWaterfall(scores) {
      const keys = ["全部", "1次", "2次", "3次", "4次", "5次", "5次以上"];
      const wordcount = [
        scores["all"],
        scores["1"] || 0,
        scores["2"] || 0,
        scores["3"] || 0,
        scores["4"] || 0,
        scores["5"] || 0,
        scores["5+"] || 0,
      ];
      const placehodler = [];
      placehodler[0] = 0;
      placehodler[1] = scores["all"] - wordcount[1];
      placehodler[2] = placehodler[1] - wordcount[2];
      placehodler[3] = placehodler[2] - wordcount[3];
      placehodler[4] = placehodler[3] - wordcount[4];
      placehodler[5] = placehodler[4] - wordcount[5];
      placehodler[6] = placehodler[5] - wordcount[6];

      var waterfallChartDom = document.getElementById("waterfall-chart");
      var waterfallChart = echarts.init(waterfallChartDom);
      var waterfalloption;

      waterfalloption = {
        title: {
          text: "单词测试统计",
          subtext: "正确次数统计图",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: function(params) {
            var tar = params[1];
            return tar.name + "<br/>" + tar.seriesName + " : " + tar.value;
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          splitLine: { show: false },
          data: keys,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "Placeholder",
            type: "bar",
            stack: "Total",
            itemStyle: {
              borderColor: "transparent",
              color: "transparent",
            },
            emphasis: {
              itemStyle: {
                borderColor: "transparent",
                color: "transparent",
              },
            },
            data: placehodler, //[0, 1700, 1400, 1200, 300, 0],
          },
          {
            name: "单词数",
            type: "bar",
            stack: "Total",
            label: {
              show: true,
              position: "inside",
            },
            data: wordcount, //[2900, 1200, 300, 200, 900, 300],
          },
        ],
      };

      waterfalloption && waterfallChart.setOption(waterfalloption);
    },
  },
};
</script>

<style scoped>
.chart {
  width: 100%;
  height: 400px;
}
</style>
