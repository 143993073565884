<template>
  <v-card elevation="2" shaped tile>
    <v-card-title style="font-size:20px">
      <span style="margin: 0 auto;min-height:50px">{{
        questions[questionIdx].ch
      }}</span>
    </v-card-title>

    <v-card-title style="font-size:20px; text-align:center">
      <span style="margin: 0 auto;min-height:50px">{{
        testResults[questionIdx]
      }}</span>
    </v-card-title>

    <keyboard
      v-model="testResults[questionIdx]"
      :layouts="[
        'qwertyuiop|asdfghjkl|{shift:goto:1}zxcvbnm|{delete:backspace}{space:space}',
        'QWERTYUIOP|ASDFGHJKL|{shift:goto:0}ZXCVBNM|{delete:backspace}{space:space}',
      ]"
      :maxlength="16"
    ></keyboard>

    <v-divider style="margin: 20px" />

    <v-btn block @click="onClickNext">
      下一题 ({{ questionIdx + 1 + "/" + this.questions.length }})
    </v-btn>

    <result-dialog
      :expected1="expected"
      :model="resultDialog"
      :exam="isExam"
    ></result-dialog>

    <!-- <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title>
          回答错误， 以下是正确答案
        </v-card-title>

        <v-card-text style="font-size:20px">
          {{ expected }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="onCloseDialog">
            关闭
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
  </v-card>
</template>

<script>
import { mixin } from "./mixin";
import { en2ch } from "../utils/tools";
import { EventBus } from "../main";
export default {
  mixins: [mixin],

  data: () => ({}),
  mounted() {
    const that = this;
    EventBus.$on("close-result-dialog", () => {
      that.onCloseDialog(false);
    });
  },
  beforeDestroy() {
    EventBus.$off("close-result-dialog");
  },
  computed: {
    questions() {
      return en2ch(this.selectedBook, this.selectedCate);
    },
    expected() {
      const expected = this.questions[this.questionIdx].word;
      return expected;
    },
  },
  methods: {
    onClickNext() {
      const expected = this.questions[this.questionIdx].word;
      const actual = this.testResults[this.questionIdx];

      if (expected === actual) {
        this.correct = true;
        if (this.questionIdx < this.questions.length - 1) this.questionIdx++;
      } else {
        this.openResultDialog();
        this.correct = false;
      }
      this.logResult(2);
    },
  },
};
</script>
