<template>
  <v-app>
    <!-- <v-navigation-drawer app> -->
    <!-- -->
    <!-- </v-navigation-drawer> -->

    <v-app-bar app dense hide-on-scroll scroll-target="#scrolling-target">
      <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->

      <v-toolbar-title>单词速记 </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon>
        <v-icon>mdi-menu</v-icon>
      </v-btn>

      <template v-slot:extension>
        <v-tabs :show-arrows="false" :value="activeKey" fixed-tabs>
          <v-tabs-slider></v-tabs-slider>

          <v-tab @click="goto('books', 0)">词库</v-tab>
          <!-- <v-tab @click="goto('words', 1)">词表</v-tab>
          <v-tab @click="goto('tests', 2)">练习</v-tab> -->
          <v-tab @click="goto('exam', 1)">测试</v-tab>
          <v-tab @click="goto('fav', 2)">生词</v-tab>
          <!-- <v-tab @click="goto('books', 5)">记忆</v-tab> -->
          <v-tab @click="goto('log', 3)">统计</v-tab>
        </v-tabs>
      </template>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main>
      
      <!-- Provides the application the proper gutter -->
      <v-container fluid id="scrolling-target">
        <breadcrumbs v-if="activeKey!=2 && activeKey!=3 " :book="bookName" :cate="cateName"></breadcrumbs>
        <v-divider></v-divider>
        <!-- If using vue-router -->
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer app>
      <!-- -->
    </v-footer>
  </v-app>

  <!-- <van-nav-bar
      fixed
      :title="title"
    >
      <template #left>
        <van-icon size="large" name="wap-nav" />
      </template>
    </van-nav-bar>

    <van-row >
      <van-col span="6">
        <van-sticky :offset-top="50">
          <van-sidebar v-model="activeKey">
            <van-sidebar-item title="词库" url="#/books" />
            <van-sidebar-item title="词表" :url="'#/words/'+bookIdx+'/'+cateIdx"/>
            <van-sidebar-item title="练习" />
            <van-sidebar-item title="测试" />
            <van-sidebar-item title="生词" />
            <van-sidebar-item title="记忆" />
            <van-sidebar-item title="统计" />
            <van-sidebar-item title="日志" />
          </van-sidebar>
        </van-sticky>
      </van-col>
      <van-col span="18">
        
      </van-col>
    </van-row> -->
  <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  <!-- <p>
      <router-link to="/foo">Go to Foo</router-link>
      <router-link to="/bar">Go to Bar</router-link>
    </p>
    <router-view></router-view> -->
</template>

<script>
// import Vue from "vue";
// import { NavBar } from "vant";
// import { Notify } from "vant";
// import { Dialog } from "vant";
// import { Collapse, CollapseItem } from "vant";
// import { Sidebar, SidebarItem } from "vant";
// import { Col, Row } from "vant";

import { mapState } from "vuex";
import breadcrumbs from "./components/Breadcrumbs";
// Vue.use(NavBar);
// Vue.use(Collapse);
// Vue.use(CollapseItem);
// Vue.use(Notify);
// Vue.use(Dialog);
// Vue.use(Sidebar);
// Vue.use(SidebarItem);
// Vue.use(Col);
// Vue.use(Row);

// import Vue from "vue";
// import HelloWorld from "./components/HelloWorld.vue";
// import VueRouter from "vue-router";
// Vue.use(VueRouter);

// const Foo = { template: "<div>foo</div>" };
// const Bar = { template: "<div>bar</div>" };

// const routes = [
//   { path: "/app", component: HelloWorld },
//   { path: "/foo", component: Foo },
//   { path: "/bar", component: Bar },
// ];

// const router = new VueRouter({
//   routes, // short for `routes: routes`
// });

// router.push({ path: "/foo" });
export default {
  name: "App",
  components: {breadcrumbs},
  data() {
    return {
      title: "速记单词",
    };
  },
  computed: {
    ...mapState(["books", "bookIdx", "cateIdx", "activeKey"]),
    day () {
      return this.$store.getters.day
    },
    bookName() {
      return this.books[this.bookIdx].name
    },
    cateName() {
      return Object.keys(this.books[this.bookIdx].words)[this.cateIdx]
    }
  },
  methods: {
    // ...mapMutations(["setActiveKey"]),
    onClickLeft() {},
    goto(name) {
      // this.setActiveKey(idx);

      this.$router.push({
        name: name,
        params: { bookIndex: this.bookIdx, cateIndex: this.cateIdx },
      });

      // console.log(idx);
    },
    // gotoBooks() {
    //   this.$router.push({'name':'books'})
    //   console.log(this.activeKey);
    // },
    // gotoWords() {
    //   this.$router.push({'name':'words', 'params':{ 'bookIdx':this.bookIdx, 'cateIdx':this.cateIdx}})
    //   console.log(this.activeKey);
    // }
  },
  // components: {
  //   HelloWorld
  // }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  /* margin-top: 60px; */
  overflow: hidden;
}
</style>
