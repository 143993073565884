import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
const books = require("./vocabulary/books");
import storage from "./utils/storage"

export default new Vuex.Store({
  state: {
    books,
    bookIdx: 0,
    cateIdx: 0,
    activeKey: 0,
    log:[]

  },
  mutations: {
    setActiveKey(state, key) {
      state.activeKey = key||0;
    },
    setBookIdx(state, key) {
      state.bookIdx = key||0;
    },
    setCateIdx(state, key) {
      state.cateIdx = key||0;
    },
    addRecord(state, record) {
      storage.addRecord(record)
    },
    addWord(state, word) {
      storage.addWordList(word[0], word[1], word[2])
    },
    removeWord(state, word) {
      storage.removeWordList(word)
    }
  },
  actions: {},
  getters: {
    day: () => {
      return storage.getDay()
    },
    allday: ()=>{
      const keys = storage.getAllRecordKeys()
      return keys
    },
    log: (day)=>{
      const records = storage.getRecord(day)
      return records
    },
    wordlist: ()=>{
      const wordlist = storage.getWordList()
      return wordlist
    }
  },
});
