
const book001 = require("./book-001.json")
console.log(Object.keys(book001).length);

module.exports = 

[
    {
        "name":"小学英语分类词汇",
        "level":"basic",
        "words":book001
    }
]