<template>
  <v-card elevation="2" shaped tile>
    <v-card-title style="font-size:40px">
      {{ questions[questionIdx].word }}
    </v-card-title>
    <v-list subheader two-line flat>
      <v-list-item-group v-model="testResults[questionIdx]">
        <v-radio-group v-model="testResults[questionIdx]">
          <v-list-item
            v-for="(option, optionIdx) in questions[questionIdx].options"
            :key="optionIdx"
          >
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-radio :input-value="active" color="primary"> </v-radio>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>{{ option }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>
        </v-radio-group>
      </v-list-item-group>
    </v-list>
    <v-btn block @click="onClickNext">
      下一题 ({{ questionIdx + 1 + "/" + this.questions.length }})
    </v-btn>

    <result-dialog :expected1="expected"></result-dialog>
  </v-card>
</template>

<script>
import { mixin } from "./mixin";
import { en2ch } from "../utils/tools";
import { EventBus } from "../main";
export default {
  mixins: [mixin],
  data: () => ({}),
  mounted() {
    const that = this;
    EventBus.$on("close-result-dialog", () => {
      that.onCloseDialog(false);
    });
  },
  beforeDestroy() {
    EventBus.$off("close-result-dialog");
  },
  computed: {
    questions() {
      return en2ch(this.selectedBook, this.selectedCate);
    },
    expected() {
      const expected = this.questions[this.questionIdx].ch;
      return expected;
    },
  },
  methods: {
    onClickNext() {
      // const expected = this.questions[this.questionIdx].ch;
      const actual = this.questions[this.questionIdx].options[
        this.testResults[this.questionIdx]
      ];
      if (this.expected == actual) {
        this.correct = true;
        if (this.questionIdx < this.questions.length - 1) {
          this.questionIdx++;
        }
      } else {
        this.openResultDialog();
        this.correct = false;
      }
      this.logResult(3);
    },
  },
};
</script>
